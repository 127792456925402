<template>
  <div class="container" v-title :data-title="$t('i18n.partsManagement')">
    <div id="outer-title">{{ $t("i18n.partsManagement") }}</div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @handleEdit="handleEdit"
        @handleDelete="handleDelete"
        @getEditData="getEditData"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import jlTable from "../../components/table";
export default {
  name: "PartsManagement",
  components: {
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const state = reactive({
      formInline: {
        pageNumber: 1,
        pageSize: 10,
      },
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: proxy.$defined.btnPermission("新增采集器"), // 是否启用新增功能
        isEdit: proxy.$defined.btnPermission("编辑采集器"), // 是否启用编辑功能
        isDelete: proxy.$defined.btnPermission("删除采集器"), // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "name",
          label: "partName",
          align: "center",
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "replaceCondition",
          label: "technicalReplacement",
          align: "left",
          areaRows: 4,
          width: 500,
          type: "textarea",
          rules: [{ required: true, message: t("i18n.input") }],
          formatter: (row) => {
            if (row.replaceCondition.indexOf("\n") >= 0) {
              return row.replaceCondition.replace(/\n/g, "<br/>");
            } else {
              return row.replaceCondition;
            }
          },
        },
        {
          prop: "replacementCycle",
          label: "replacementCycle",
          align: "center",
          row: false,
        },
        {
          prop: "range",
          label: "replacementCycle",
          rows: false,
          type: "slider",
          min: 1,
          max: 20,
          marks: {
            1: "1",
            2: "2",
            3: "3",
            4: "4",
            5: "5",
            6: "6",
            7: "7",
            8: "8",
            9: "9",
            10: "10",
            11: "11",
            12: "12",
            13: "13",
            14: "14",
            15: "15",
            16: "16",
            17: "17",
            18: "18",
            19: "19",
            20: "20",
          },
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "norm",
          label: "units",
          rows: false,
          type: "select",
          props: { label: "locale", value: "value" },
          data: [
            {
              locale: "annually",
              value: 0,
            },
            {
              locale: "monthly",
              value: 1,
            },
          ],
          rules: [{ required: true, message: t("i18n.select") }],
        },
        {
          prop: "remark",
          label: "remarks",
          align: "left",
          type: "textarea",
          rules: [{ required: true, message: t("i18n.input") }],
          formatter: (row) => {
            if (row.remark.indexOf("\n") >= 0) {
              return row.remark.replace(/\n/g, "<br/>");
            } else {
              return row.remark;
            }
          },
        },
      ],
      operates: {
        // 列操作按钮
        width: 140,
        fixed: "right",
        list: [],
      },
      total: 0,
      loading: true,
      editData: {},
    });

    const init = async (params) => {
      state.loading = true;
      const { data } = await proxy.$api.detection.partManagePage(params);
      data.records.map((item) => {
        item.range = [item.minHp, item.maxHp];
      });
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    const handleEdit = async (val, pages) => {
      // 新增/编辑数据
      let msg = val.id ? "edit" : "new";
      val.minHp = val.range[0];
      val.maxHp = val.range[1];
      if (val.id) {
        for (let k in val) {
          if (val[k] !== undefined) {
            state.editData[k] = val[k];
          }
        }
        val = JSON.parse(JSON.stringify(state.editData));
      }
      val.id
        ? await proxy.$api.detection.editPartManage(val.id, val)
        : await proxy.$api.detection.addPartManage(val);
      proxy.$defined.tip(t("i18n." + msg) + t("i18n.success"), "success");
      // 初始化 刷新
      init(pages);
    };

    const handleDelete = (row, pages) => {
      proxy.$api.detection.delPartManage(row.id).then(() => {
        init(pages);
        proxy.$defined.tip(t("i18n.delete") + t("i18n.success"), "success");
      });
    };

    const getEditData = (row) => {
      state.editData = row;
    };

    return {
      ...toRefs(state),
      handleEdit,
      init,
      handleDelete,
      getEditData,
    };
  },
};
</script>
